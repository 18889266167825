import { Identifiable } from 'entities/common';
import { Product } from 'entities/Product';
import CrudAPI from './crud.api';

class ProductAPI extends CrudAPI<Product> {
  constructor() {
    super();
    this.basePath = '/products';
  }

  async findBySKU(sku: string) {
    const result = await this.get<Identifiable<Product>>({
      path: '/_sku',
      query: { sku },
    });
    return result;
  }
}

export default ProductAPI;
