/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { lazy } from 'react';
import {
  faAddressBook, faFileInvoice, faHome, faIndustry, faMoneyBillWave, faPeopleArrows, faSignInAlt, faStore, faTags, faUser,
} from '@fortawesome/free-solid-svg-icons';

import { UserRole } from 'entities/User';
import { RouteType } from '../custom';

const ViewSalesInvoice = lazy(() => import('views/invoices/customer/ViewSalesInvoice'));
const EditSalesInvoice = lazy(() => import('views/invoices/customer/EditSalesInvoice'));
const AddSalesInvoice = lazy(() => import('views/invoices/customer/AddSalesInvoice'));
const ListSalesInvoice = lazy(() => import('views/invoices/customer/ListSalesInvoice'));
const ViewUser = lazy(() => import('views/users/ViewUser'));
const ListCustomer = lazy(() => import('views/customers/ListCustomer'));
const AddCustomer = lazy(() => import('views/customers/AddCustomer'));
const EditCustomer = lazy(() => import('views/customers/EditCustomer'));
const ViewCustomer = lazy(() => import('views/customers/ViewCustomer'));
const AddCustomerAddress = lazy(() => import('views/customer-addresses/AddCustomerAddress'));
const EditCustomerAddress = lazy(() => import('views/customer-addresses/EditCustomerAddress'));
const EditUser = lazy(() => import('views/users/EditUser'));
const ListUser = lazy(() => import('views/users/ListUser'));
const AddUser = lazy(() => import('views/users/AddUser'));
const Login = lazy(() => import('views/Login'));
const Overview = lazy(() => import('views/overview/Overview'));
const Tables = lazy(() => import('views/examples/Tables'));
const AddSupplier = lazy(() => import('views/suppliers/AddSupplier'));
const ViewSupplier = lazy(() => import('views/suppliers/ViewSupplier'));
const ListSupplier = lazy(() => import('views/suppliers/ListSupplier'));
const EditSupplier = lazy(() => import('views/suppliers/EditSupplier'));
const AddProduct = lazy(() => import('views/products/AddProduct'));
const ListProduct = lazy(() => import('views/products/ListProduct'));
const EditProduct = lazy(() => import('views/products/EditProduct'));
const ViewProduct = lazy(() => import('views/products/ViewProduct'));
const AddPurchaseInvoice = lazy(() => import('views/invoices/supplier/AddPurchaseInvoice'));
const ListPurchaseInvoice = lazy(() => import('views/invoices/supplier/ListPurchaseInvoice'));
const ViewPurchaseInvoice = lazy(() => import('views/invoices/supplier/ViewPurchaseInvoice'));
const EditPurchaseInvoice = lazy(() => import('views/invoices/supplier/EditPurchaseInvoice'));

const routes: RouteType = [
  {
    path: '/index',
    name: 'Overview',
    icon: 'ni ni-tv-2 text-primary',
    component: () => <Overview />,
    faIcon: faHome,
    layout: '/admin',
    search: true,
  },
  {
    path: '/suppliers/create',
    name: 'Add Supplier',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <AddSupplier />,
    faIcon: faIndustry,
    layout: '/admin',
    hide: true,
    search: true,
  },
  {
    path: '/suppliers/:id/edit',
    name: 'Edit Supplier',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <EditSupplier />,
    faIcon: faIndustry,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/suppliers/:id',
    name: 'Supplier',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ViewSupplier />,
    faIcon: faIndustry,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ListSupplier />,
    faIcon: faIndustry,
    layout: '/admin',
    search: true,
  },
  {
    path: '/products/create',
    name: 'Add Product',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <AddProduct />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
    search: true,
  },
  {
    path: '/products/:id/edit',
    name: 'Edit Product',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <EditProduct />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/products/:id',
    name: 'Product',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ViewProduct />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/products',
    name: 'Products',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ListProduct />,
    faIcon: faTags,
    layout: '/admin',
    search: true,
  },
  {
    path: '/customers/:customerId/addresses/create',
    name: 'Add Customer Address',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <AddCustomerAddress />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/customers/:customerId/addresses/:id/edit',
    name: 'Edit Customer Address',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <EditCustomerAddress />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/customers/create',
    name: 'Add Customer',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <AddCustomer />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
    search: true,
  },
  {
    path: '/customers/:id/edit',
    name: 'Edit Customer',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <EditCustomer />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/customers/:id',
    name: 'Customer',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ViewCustomer />,
    faIcon: faTags,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/customers',
    name: 'Customers',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ListCustomer />,
    faIcon: faAddressBook,
    layout: '/admin',
    search: true,
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <Login />,
    layout: '/auth',
    faIcon: faSignInAlt,
    hide: true,
  },
  {
    path: '/invoices',
    name: 'Invoices',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <Tables />,
    layout: '/admin',
    faIcon: faFileInvoice,
    subMenu: [
      {
        path: '/invoices/supplier/create',
        name: 'Add Purchase Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <AddPurchaseInvoice />,
        layout: '/admin',
        faIcon: faStore,
        hide: true,
        search: true,
      },
      {
        path: '/invoices/supplier/:id/edit',
        name: 'Edit Purchase Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <EditPurchaseInvoice />,
        layout: '/admin',
        faIcon: faStore,
        hide: true,
      },
      {
        path: '/invoices/supplier/:id',
        name: 'View Purchase Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <ViewPurchaseInvoice />,
        layout: '/admin',
        faIcon: faStore,
        hide: true,
      },
      {
        path: '/invoices/supplier',
        name: 'Purchase Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <ListPurchaseInvoice />,
        layout: '/admin',
        faIcon: faStore,
        search: true,
      },
      {
        path: '/invoices/customer/create',
        name: 'Add Sales Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <AddSalesInvoice />,
        layout: '/admin',
        faIcon: faMoneyBillWave,
        hide: true,
        search: true,
      },
      {
        path: '/invoices/customer/:id/edit',
        name: 'Edit Sales Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <EditSalesInvoice />,
        layout: '/admin',
        faIcon: faMoneyBillWave,
        hide: true,
      },
      {
        path: '/invoices/customer/:id',
        name: 'View Sales Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <ViewSalesInvoice />,
        layout: '/admin',
        faIcon: faMoneyBillWave,
        hide: true,
      },
      {
        path: '/invoices/customer',
        name: 'Sales Invoice',
        icon: 'ni ni-bullet-list-67 text-red',
        component: () => <ListSalesInvoice />,
        layout: '/admin',
        faIcon: faMoneyBillWave,
        search: true,
      },
    ],
  },
  {
    path: '/users/create',
    name: 'Users',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <AddUser />,
    faIcon: faUser,
    layout: '/admin',
    search: true,
    hide: true,
    ability: [UserRole.ADMIN],
  },
  {
    path: '/users/:id/edit',
    name: 'Users',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <EditUser />,
    faIcon: faUser,
    layout: '/admin',
    hide: true,
    ability: [UserRole.ADMIN],
  },
  {
    path: '/users/:id',
    name: 'Users',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ViewUser />,
    faIcon: faUser,
    layout: '/admin',
    search: true,
    hide: true,
    ability: [UserRole.ADMIN],
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'ni ni-bullet-list-67 text-red',
    component: () => <ListUser />,
    faIcon: faUser,
    layout: '/admin',
    search: true,
    ability: [UserRole.ADMIN],
  },
];
export default routes;
