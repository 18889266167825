export enum UserRole {
  ADMIN = 'admin',
  ACCOUNTING = 'accounting',
}

export interface User {
  _id: string;
  username: string;
  name: string;
  role: UserRole;
}

export interface UserDTO extends Omit<User, '_id'> {
  password?: string;
}

export const userRoles = [
  {
    value: UserRole.ADMIN,
    label: 'Administrator',
    description: 'Access to all sections.',
  },
  {
    value: UserRole.ACCOUNTING,
    label: 'Accounting',
    description: 'Access to all invoices except sales numbers.',
  },
];

export const userRoleMap: Record<UserRole, typeof userRoles[number]> = userRoles
  .reduce<Record<UserRole, typeof userRoles[number]>>((acc, v) => {
  acc[v.value] = v;
  return acc;
}, {} as Record<UserRole, typeof userRoles[number]>);
