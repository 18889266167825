/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

interface EmptyHeaderProps {
  backLink?: string;
}

const EmptyHeader: FC<EmptyHeaderProps> = ({ backLink }) => (
  <div className="header bg-primary pb-8 pt-5 pt-md-8">
    {Boolean(backLink) && (
      <Container fluid>
        <Button tag={Link} to={backLink}>{'< Back to Previous'}</Button>
      </Container>
    )}
  </div>
);

export default EmptyHeader;
