import AuthAPI from './auth.api';
import ProductAPI from './product.api';
import SupplierInvoiceAPI from './supplier-invoice.api';
import SupplierAPI from './supplier.api';
import UserAPI from './user.api';
import CustomerAPI from './customer.api';
import CountryAPI from './country.api';
import CustomerAddressAPI from './customer-address.api';
import SalesInvoiceAPI from './customer-invoice.api';
import StatisticAPI from './statistic.api';

class HttpClient {
  constructor(
    readonly auth: AuthAPI,
    readonly supplier: SupplierAPI,
    readonly product: ProductAPI,
    readonly supplierInvoice: SupplierInvoiceAPI,
    readonly user: UserAPI,
    readonly customer: CustomerAPI,
    readonly country: CountryAPI,
    readonly customerAddress: CustomerAddressAPI,
    readonly salesInvoice: SalesInvoiceAPI,
    readonly statistic: StatisticAPI,
  ) {}
}

const httpClient = new HttpClient(
  new AuthAPI(),
  new SupplierAPI(),
  new ProductAPI(),
  new SupplierInvoiceAPI(),
  new UserAPI(),
  new CustomerAPI(),
  new CountryAPI(),
  new CustomerAddressAPI(),
  new SalesInvoiceAPI(),
  new StatisticAPI(),
);

export default httpClient;
