/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  faSearch, faSignOutAlt, faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import httpClient from 'api';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Fuse from 'fuse.js';
import routes from 'routes';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';
import useAuth from 'hooks/useAuth';
import sshkIcon from '../../assets/img/brand/sshk-icon.png';
import { Route } from '../../../custom';

type Props = {
  brandText: string;
};

const flattenedRoutes = routes.flatMap((route) => {
  if (route.subMenu) {
    return route.subMenu;
  }
  return [route];
}).filter(({ search }) => search);

const searchInstance = new Fuse(flattenedRoutes, {
  keys: ['path', 'name'],
});

const AdminSearchBar: FC<{}> = () => {
  const [search, setSearch] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Fuse.FuseResult<Route>[]>([]);

  const resetSearch = useCallback(() => setSearch(''), []);

  useEffect(() => {
    setSearchResults(searchInstance.search(search));
  }, [search]);

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav>
        <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
          <FormGroup className="mb-0">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Search Pages" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>
          </FormGroup>
        </Form>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header className="text-black">Search Results</DropdownItem>
        {searchResults.length ? searchResults.map(({
          item: {
            name, path, faIcon, layout,
          },
        }) => (
          <DropdownItem tag={Link} key={path} to={layout + path} onClick={resetSearch}>
            <FontAwesomeIcon icon={faIcon} style={{ width: 50 }} />
            {name}
          </DropdownItem>
        )) : (
          <DropdownItem>No results found</DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const AdminNavbar: FC<Props> = ({ brandText }) => {
  const history = useHistory();
  const { mutate: logout, isLoading } = useMutation(() => httpClient.auth.logout(), {
    onSuccess: () => {
      history.push('/auth/login');
    },
  });
  const { user } = useAuth();

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <Link
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          to="/"
        >
          {brandText}
        </Link>
        <Nav className="align-items-center d-none d-md-flex justify-content-end flex-grow-1" navbar>
          <AdminSearchBar />
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {user?.name}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
