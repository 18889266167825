import ApiError from './ApiError';

class ConflictApiError<T = Record<string, string>> extends ApiError {
  conflicts: Record<keyof T, string>;

  conflictKeys: (keyof T)[];

  constructor(conflictData: Record<keyof T, string>) {
    super('A conflict has been detected in the data.');
    this.conflicts = conflictData;
    this.conflictKeys = Object.keys(conflictData) as (keyof T)[];
  }

  static isConflictError<Res>(obj: any): obj is ConflictApiError<Res> {
    return obj.conflicts;
  }
}

export default ConflictApiError;
