import { User } from 'entities/User';
import { LoginResponse } from './auth.interface';
import BaseAPI from './base.api';

class AuthAPI extends BaseAPI {
  constructor() {
    super();

    this.basePath = '/auth';
  }

  public me() {
    return this.get<User>({
      path: '/',
    });
  }

  public login(username: string, password: string) {
    return this.post<LoginResponse>({
      path: '/login',
      body: {
        username,
        password,
      },
    });
  }

  public logout() {
    return this.post<{ message: string }>({
      path: '/logout',
    });
  }
}

export default AuthAPI;
