import React from 'react';
import {
  Collapse, CardBody, Card, NavItem, NavLink,
} from 'reactstrap';

import {
  NavLink as NavLinkRRD,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import '../../assets/css/SidebarDropdownItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Route } from '../../../custom';

type SidebarDropdownItemProps = Route & {
  location: {
    pathname: string;
  };
  subMenu: Route[];
};
class SidebarDropdownItem extends React.Component<
SidebarDropdownItemProps & RouteComponentProps,
{ isOpen: boolean }
> {
  constructor(props: SidebarDropdownItemProps & RouteComponentProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { subMenu, location } = this.props;
    subMenu.forEach(({ path, layout }) => {
      if (location.pathname === layout + path) {
        this.toggle(null);
      }
    });
  }

  toggle = (event?: MouseEvent | null) => {
    if (event) {
      event.preventDefault();
    }
    this.setState(
      ({ isOpen }) => ({ isOpen: !isOpen }),
      () => {
        const { name } = this.props;
        const { isOpen } = this.state;
        const openArrow: HTMLElement | null = document.querySelector(
          `.ni.ni-bold-down.ml-2#sidebarMenu${name}`,
        );
        if (openArrow !== null) {
          openArrow.style.transform = `rotate(${isOpen ? -180 : 0}deg)`;
        }
      },
    );
  };

  render() {
    const {
      name, subMenu, faIcon,
    } = this.props;
    const { isOpen } = this.state;
    return (
      <>
        <NavItem>
          <NavLink onClick={() => this.toggle()} className="d-flex justify-content-between" style={{ cursor: 'pointer' }}>
            <div>
              <FontAwesomeIcon icon={faIcon} style={{ width: 20, marginRight: 16 }} />
              {name}
            </div>
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="ml-2" />
            {/* <span className="ni ni-bold-down ml-2" id={`sidebarMenu${name}`} /> */}
          </NavLink>
        </NavItem>
        <Collapse isOpen={isOpen}>
          <Card className="border-0">
            <CardBody className="py-0 pl-4">
              {subMenu.filter(({ hide }) => !hide).map((prop, key) => (
                <NavItem key={prop.name}>
                  <NavLink
                    to={prop.layout + prop.path}
                    tag={NavLinkRRD}
                    activeClassName="active"
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={prop.faIcon} style={{ width: 20, marginRight: 16 }} />
                    {prop.name}
                  </NavLink>
                </NavItem>
              ))}
            </CardBody>
          </Card>
        </Collapse>
      </>
    );
  }
}

export default withRouter(SidebarDropdownItem);
