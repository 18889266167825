import { User, UserDTO } from 'entities/User';
import CrudAPI from './crud.api';

class UserAPI extends CrudAPI<User, UserDTO> {
  constructor() {
    super();
    this.basePath = '/users';
  }
}

export default UserAPI;
