/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { FC, Suspense, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
import { matchPath, Redirect } from 'react-router';
// Routes
import Loading from 'views/Loading';
import routes from '../routes';
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar';
import AdminFooter from '../components/Footers/AdminFooter';
import Sidebar from '../components/Sidebar/Sidebar';
import sneakerSurge from '../assets/img/brand/sneaker-surge.png';
import { RouteType } from '../../custom';

type Props = {
  location: {
    pathname: string;
  };
};

const allRoutes = routes.flatMap((route) => {
  if (route.subMenu) return route.subMenu;
  return [route];
});

const Admin: FC<Props> = (props) => {
  const { location: { pathname } } = props;

  const brandText = useMemo(() => {
    for (let i = 0; i < allRoutes.length; i += 1) {
      const route = allRoutes[i];
      if (matchPath(pathname, route.layout + route.path)) {
        return route.name;
      }
    }
    return 'Sneaker Surge';
  }, [pathname]);

  return (
    <>
      <Sidebar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        routes={routes}
        logo={{
          innerLink: '/admin/index',
          // eslint-disable-next-line global-require
          imgSrc: sneakerSurge,
          imgAlt: 'Logo',
        }}
      />
      <div className="main-content">
        <AdminNavbar
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          brandText={brandText}
        />
        <Suspense fallback={<Loading />}>
          <Switch>
            {allRoutes.map(({ layout, path, component: Component }) => (
              <Route
                exact
                path={layout + path}
                component={Component}
                key={layout + path}
              />
            ))}
            <Redirect from="/admin" to="/admin/index" />
          </Switch>
        </Suspense>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
