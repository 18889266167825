import { Country } from 'entities/Country';
import { Customer } from 'entities/Customer';
import BaseAPI from './base.api';
import CrudAPI from './crud.api';

class CountryAPI extends BaseAPI {
  constructor() {
    super();
    this.basePath = '/countries';
  }

  async getAll(q?: string): Promise<Country[]> {
    return this.get<Country[]>({
      path: '/',
      query: {
        q,
      },
    });
  }

  async getByCode(code: string): Promise<Country> {
    return this.get<Country>({
      path: `/${code}`,
    });
  }
}

export default CountryAPI;
