import { Customer } from 'entities/Customer';
import CrudAPI from './crud.api';

class CustomerAPI extends CrudAPI<Customer> {
  constructor() {
    super();
    this.basePath = '/customers';
  }
}

export default CustomerAPI;
