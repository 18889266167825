import httpClient from 'api';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UserRole } from '../entities/User';

const useAuth = () => {
  const { data, isFetching } = useQuery('auth', () => httpClient.auth.me(), {
    retry: false,
    cacheTime: 30,
  });

  const isAdmin = useMemo(() => data?.role === UserRole.ADMIN, [data]);

  return {
    user: data,
    isLoading: isFetching,
    isAdmin,
  };
};

export default useAuth;
