import { Supplier } from 'entities/Supplier';
import CrudAPI from './crud.api';

class SupplierAPI extends CrudAPI<Supplier> {
  constructor() {
    super();
    this.basePath = '/suppliers';
  }
}

export default SupplierAPI;
