import ApiError from './ApiError';

class TooLargeError<T = Record<string, string>> extends ApiError {
  isTooLarge: boolean;

  constructor(message?: string) {
    super(message ?? 'File too large');
    this.isTooLarge = true;
  }

  static isTooLargeError<Res>(obj: any): obj is TooLargeError<Res> {
    return obj.isTooLarge;
  }
}

export default TooLargeError;
