import { CustomerStats } from 'entities/Customer';
import { PurchaseInvoiceStatistic } from 'entities/PurchaseInvoice';
import { SalesInvoiceStatistic } from 'entities/SalesInvoice';
import { Totals } from 'entities/Statistic';
import BaseAPI from './base.api';

class StatisticAPI extends BaseAPI {
  constructor() {
    super();
    this.basePath = '/statistics';
  }

  async getMonthlySalesStats(): Promise<SalesInvoiceStatistic[]> {
    return this.get<SalesInvoiceStatistic[]>({
      path: '/sales-invoices/monthly',
    });
  }

  async getWeeklySalesStats(): Promise<SalesInvoiceStatistic[]> {
    return this.get<SalesInvoiceStatistic[]>({
      path: '/sales-invoices/weekly',
    });
  }

  async getMonthlyPurchaseStats(): Promise<PurchaseInvoiceStatistic[]> {
    return this.get<PurchaseInvoiceStatistic[]>({
      path: '/supplier-invoices/monthly',
    });
  }

  async getWeeklyPurchaseStats(): Promise<PurchaseInvoiceStatistic[]> {
    return this.get<PurchaseInvoiceStatistic[]>({
      path: '/supplier-invoices/weekly',
    });
  }

  async getTotals(): Promise<Totals> {
    return this.get<Totals>({
      path: '/totals',
    });
  }

  async getCustomerStats(customerId: string): Promise<CustomerStats> {
    return this.get<CustomerStats>({
      path: `/customers/${customerId}`,
    });
  }
}

export default StatisticAPI;
