import ApiError from './ApiError';

class UnprocessableEntityApiError<T = Record<string, string>> extends ApiError {
  isUnprocessable: boolean;

  constructor(message: string) {
    super(message);
    this.isUnprocessable = true;
  }

  static isUnprocessableEntityError<Res>(obj: any): obj is UnprocessableEntityApiError<Res> {
    return obj.isUnprocessable;
  }
}

export default UnprocessableEntityApiError;
