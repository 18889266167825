/* eslint-disable @typescript-eslint/default-param-last */
import { Identifiable, PaginatedResult } from 'entities/common';
import { SalesInvoice, SalesInvoiceDTO, SalesInvoiceStatistic } from 'entities/SalesInvoice';
import CrudAPI from './crud.api';

class SalesInvoiceAPI extends CrudAPI<SalesInvoice, SalesInvoiceDTO> {
  constructor() {
    super();
    this.basePath = '/sales-invoices';
  }

  async getAllWithRange(
    page: number,
    q: string | undefined = undefined,
    limit = 50,
    startDate: Date | null,
    endDate: Date | null,
  ): Promise<PaginatedResult<Identifiable<SalesInvoice>>> {
    return this.get<PaginatedResult<Identifiable<SalesInvoice>>>({
      path: '/',
      query: {
        page,
        limit,
        q,
        startDate: startDate ?? new Date('2000-01-01'),
        endDate: endDate ?? new Date('9999-12-31'),
      },
    });
  }
}

export default SalesInvoiceAPI;
