import { Identifiable } from 'entities/common';
import { Customer } from 'entities/Customer';
import { CustomerAddress } from 'entities/CustomerAddress';
import BaseAPI from './base.api';
import CrudAPI from './crud.api';

class CustomerAddressAPI extends BaseAPI {
  constructor() {
    super();
    this.basePath = '/customers';
  }

  async create(customerId: string, createDto: CustomerAddress): Promise<Identifiable<CustomerAddress>> {
    const result = await this.post<Identifiable<CustomerAddress>, CustomerAddress>({
      path: `/${customerId}/addresses`,
      body: createDto,
    });
    return result;
  }

  async updateById(customerId: string, id: string, updateDto: Partial<CustomerAddress>): Promise<Identifiable<CustomerAddress>> {
    const result = await this.patch<Identifiable<CustomerAddress>, Partial<CustomerAddress>>({
      path: `/${customerId}/addresses/${id}`,
      body: updateDto,
    });
    return result;
  }

  async getAll(customerId: string): Promise<Identifiable<CustomerAddress>[]> {
    const result = await this.get<Identifiable<CustomerAddress>[]>({
      path: `/${customerId}/addresses`,
    });
    return result;
  }

  async getById(customerId: string, id: string): Promise<Identifiable<CustomerAddress>> {
    const result = await this.get<Identifiable<CustomerAddress>>({
      path: `/${customerId}/addresses/${id}`,
    });
    return result;
  }

  async deleteById(customerId: string, id: string): Promise<Identifiable<CustomerAddress>> {
    const result = await this.delete<Identifiable<CustomerAddress>>({
      path: `/${customerId}/addresses/${id}`,
    });
    return result;
  }
}

export default CustomerAddressAPI;
