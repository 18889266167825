/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar';
import AuthFooter from '../components/Footers/AuthFooter';

import routes from '../routes';
import { RouteType } from '../../custom';

type Props = {
  location: {
    pathname: string;
  };
};

class Auth extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  getRoutes = (layoutRoutes: RouteType) => routes.map((prop, key) => {
    if (prop.layout === '/auth') {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={Math.random()}
        />
      );
    }
    return null;
  });

  render() {
    return (
      <>
        <div className="main-content">
          <AuthNavbar />
          <div className="header py-7 py-lg-8" />
          {/* Page content */}
          <Container className="mt--8 pb-4">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
