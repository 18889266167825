import { Identifiable, PaginatedResult } from 'entities/common';
import BaseAPI from './base.api';

class CrudAPI<T, CreateDTO = T> extends BaseAPI {
  async create(createDto: CreateDTO): Promise<Identifiable<T>> {
    const result = await this.post<Identifiable<T>, CreateDTO>({
      path: '/',
      body: createDto,
    });
    return result;
  }

  async update(id: string, updateDto: Partial<CreateDTO>): Promise<Identifiable<T>> {
    const result = await this.patch<Identifiable<T>, Partial<CreateDTO>>({
      path: `/${id}`,
      body: updateDto,
    });
    return result;
  }

  async getById(id: string): Promise<Identifiable<T>> {
    const result = await this.get<Identifiable<T>>({
      path: `/${id}`,
    });
    return result;
  }

  async deleteById(id: string): Promise<Identifiable<T>> {
    const result = await this.delete<Identifiable<T>>({
      path: `/${id}`,
    });
    return result;
  }

  async getAll(page: number, q: string | undefined = undefined, limit = 50): Promise<PaginatedResult<Identifiable<T>>> {
    return this.get<PaginatedResult<Identifiable<T>>>({
      path: '/',
      query: {
        page,
        limit,
        q,
      },
    });
  }
}

export default CrudAPI;
