class ApiError extends Error {
  _apiError: boolean;

  constructor(message: string) {
    super(message ?? 'An error has occurred in the API.');
    this._apiError = true;
  }

  static isApiError(obj: any): obj is ApiError {
    return obj._apiError;
  }
}

export default ApiError;
