import routes from 'routes';
import { RouteType } from '../../custom';
import useAuth from './useAuth';

const useRoutes = (): RouteType => {
  const auth = useAuth();

  return routes.filter((route) => {
    if (!route.ability) {
      return true;
    }
    if (!auth.user) {
      return false;
    }
    return route.ability.includes(auth.user.role);
  });
};

export default useRoutes;
