/* eslint-disable @typescript-eslint/default-param-last */
import { Identifiable, PaginatedResult } from 'entities/common';
import { PurchaseInvoice, PurchaseInvoiceDTO, PurchaseInvoiceStatistic } from 'entities/PurchaseInvoice';
import CrudAPI from './crud.api';

class SupplierInvoiceAPI extends CrudAPI<PurchaseInvoice, PurchaseInvoiceDTO> {
  constructor() {
    super();
    this.basePath = '/supplier-invoices';
  }

  async getAllWithRange(
    page: number,
    q: string | undefined = undefined,
    limit = 50,
    startDate: Date | null,
    endDate: Date | null,
  ): Promise<PaginatedResult<Identifiable<PurchaseInvoice>>> {
    return this.get<PaginatedResult<Identifiable<PurchaseInvoice>>>({
      path: '/',
      query: {
        page,
        limit,
        q,
        startDate: startDate ?? new Date('2000-01-01'),
        endDate: endDate ?? new Date('9999-12-31'),
      },
    });
  }

  async viewCustomInvoiceById(id: string): Promise<string> {
    const result = await this.get({
      path: `/${id}/custom-invoice`,
      responseType: 'blob',
    });
    const objectUrl = URL.createObjectURL(result as unknown as Blob);
    return objectUrl;
  }

  async uploadCustomInvoiceById(id: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.put({
      path: `/${id}/custom-invoice`,
      body: formData,
    });
  }

  async deleteCustomInvoiceById(id: string) {
    return this.delete({
      path: `/${id}/custom-invoice`,
    });
  }
}

export default SupplierInvoiceAPI;
