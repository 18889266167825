import ApiError from './ApiError';

class UnauthorizedError<T = Record<string, string>> extends ApiError {
  isUnauthorized: boolean;

  constructor(message?: string) {
    super(message ?? 'Unauthorized.');
    this.isUnauthorized = true;
  }

  static isUnauthorizedError<Res>(obj: any): obj is UnauthorizedError<Res> {
    return obj.conflicts;
  }
}

export default UnauthorizedError;
