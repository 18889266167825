import axios, { AxiosInstance, ResponseType } from 'axios';
import { parseISO } from 'date-fns';
import urljoin from 'url-join';
import ApiError from './errors/ApiError';
import ConflictApiError from './errors/ConflictApiError';
import TooLargeError from './errors/TooLargeError';
import UnauthorizedError from './errors/UnauthorizedError';
import UnprocessableEntityApiError from './errors/UnprocessableEntityApiError';
import ValidationApiError from './errors/ValidationApiError';

const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(body)) {
    const value = body[key];
    // eslint-disable-next-line no-param-reassign
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === 'object') handleDates(value);
  }

  return body;
}

abstract class BaseAPI {
  private host: string;

  private client: AxiosInstance;

  constructor() {
    this.host = '/api/v1';
    this.client = axios.create({
      baseURL: this.host,
    });

    this.client.interceptors.response.use((originalResponse) => {
      handleDates(originalResponse.data);
      return originalResponse;
    });

    this.client.interceptors.response.use(
      (resp) => resp,
      (err) => {
        if (err && axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            if (!window.location.href.includes('/login')) {
              const params = new URLSearchParams();
              params.set('redirect', window.location.pathname);
              window.location.replace(`/auth/login?${params.toString()}`);
            } else {
              return Promise.reject(new UnauthorizedError(err.response.data.message));
            }
          }
          if (err.response?.status === 400 && Array.isArray(err.response.data.message)) {
            return Promise.reject(new ValidationApiError(err.response.data.message));
          }
          if (err.response?.status === 409) {
            return Promise.reject(new ConflictApiError(err.response.data?.data));
          }
          if (err.response?.status === 413) {
            return Promise.reject(new TooLargeError(err.response.data?.message));
          }
          if (err.response?.status === 422) {
            return Promise.reject(new UnprocessableEntityApiError(err.response.data?.message));
          }
        }
        return Promise.reject(new ApiError('An error has occurred in the server.'));
      },
    );
  }

  protected basePath = '';

  protected getFullPath(path: string) {
    return urljoin(this.basePath, path);
  }

  protected async get<T = Record<string, unknown>, Qs = Record<string, unknown>>({
    path,
    query,
    responseType,
  }: {
    path: string;
    query?: Qs;
    responseType?: ResponseType,
  }) {
    const fullPath = this.getFullPath(path);
    const resp = await this.client.get<T>(fullPath, {
      params: query,
      responseType,
    });
    return resp.data;
  }

  protected async post<T = Record<string, unknown>, TBody = Record<string, unknown>, Qs = Record<string, unknown>>({
    path,
    body,
    query,
  }: {
    path: string;
    body?: TBody;
    query?: Qs;
  }) {
    const fullPath = this.getFullPath(path);
    const resp = await this.client.post<T>(fullPath, body ?? {}, {
      params: query,
    });
    return resp.data;
  }

  protected async patch<T = Record<string, unknown>, TBody = Record<string, unknown>, Qs = Record<string, unknown>>({
    path,
    body,
    query,
  }: {
    path: string;
    body?: TBody;
    query?: Qs;
  }) {
    const fullPath = this.getFullPath(path);
    const resp = await this.client.patch<T>(fullPath, body ?? {}, {
      params: query,
    });
    return resp.data;
  }

  protected async put<T = Record<string, unknown>, TBody = Record<string, unknown>, Qs = Record<string, unknown>>({
    path,
    body,
    query,
  }: {
    path: string;
    body?: TBody;
    query?: Qs;
  }) {
    const fullPath = this.getFullPath(path);
    const resp = await this.client.put<T>(fullPath, body, {
      params: query,
    });
    return resp.data;
  }

  protected async delete<T = Record<string, unknown>, Qs = Record<string, unknown>>({
    path,
    query,
  }: {
    path: string;
    query?: Qs;
  }) {
    const fullPath = this.getFullPath(path);
    const resp = await this.client.delete<T>(fullPath, {
      params: query,
    });
    return resp.data;
  }
}

export default BaseAPI;
