/* eslint-disable react/jsx-props-no-spreading */
/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

import 'react-phone-input-2/lib/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/lausanne/styles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';
import './assets/css/custom.css';
import { Suspense } from 'react';
import Loading from 'views/Loading';
import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/index" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById('root'),
);
